<template>
  <div>
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div v-show="isLoaded">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="10"
        >
          <div v-if="hasPermissionToRead">

            <b-card
              no-body
              class="mb-0 mt-2"
            >
              <b-card-title
                class="mt-1 mx-2 mb-0"
              >
                <div
                  class="d-flex align-items-center"
                >
                  <svg-icon
                    type="mdi"
                    :path="mdiFileCertificateOutline"
                    class="mr-50"
                  />
                  <span class="d-inline">Atestados</span>
                </div>
              </b-card-title>
              <hr class="mt-50">
              <div class="mr-2 ml-2 mb-1">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <div>
                      <b-form-group
                        label="Exibir"
                        label-for="idSearchBox"
                        class="mb-0"
                      >
                        <v-select
                          id="idSearchBox"
                          v-model="perPage"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="perPageOptions"
                          :clearable="false"
                          class="per-page-selector d-inline-block"
                        >
                          <span slot="no-options">Não há opções para essa quantidade.</span>
                        </v-select>
                      </b-form-group>
                    </div>
                    <b-form-input
                      v-model.lazy="searchQuery"
                      placeholder="Buscar"
                      type="text"
                      class="d-inline-block mt-2 ml-1"
                    />
                  </b-col>
                </b-row>
              </div>
              <b-table
                class="position-relative"
                primary-key="id"
                responsive
                show-empty
                striped
                hover
                empty-text="Não há atestados cadastrados."
                :items="data"
                :fields="tableColumns"
                :sort-by.sync="sortBy"
                :sort-desc.sync="isSortDirDesc"
                @row-clicked="edit"
              >
                <template #cell(field_id)="field">
                  <b-media vertical-align="center">
                    <b-link
                      class="font-weight-bold d-block text-nowrap"
                    >
                      #{{ field.item.id }}
                    </b-link>
                  </b-media>
                </template>

                <template #cell(field_client)="field">
                  <span class="font-weight-bold text-nowrap">
                    {{ field.item.client_set.name }}
                  </span>
                </template>

                <template #cell(field_status)="field">
                  <div
                    class="d-block text-nowrap"
                  >
                    <b-badge
                      pill
                      class="text-capitalize"
                      :style="{'background-color': field.item.status_set.color, 'min-width': '110px'}"
                    >
                      {{ field.item.status_set.name }}
                    </b-badge>
                  </div>
                </template>

                <template #cell(field_end_authentication)="field">
                  <span class="font-weight-bold text-nowrap">
                    {{ field.item.end_authentication }}
                  </span>
                </template>

                <template #cell(field_item_annotation)="field">
                  <span class="font-weight-bold text-nowrap">
                    {{ field.item.annotation }}
                  </span>
                </template>

                <template #cell(field_actions)="field">
                  <div>
                    <b-dropdown
                      variant="link"
                      no-caret
                      :dropup="true"
                      :boundary="'scrollParent'"
                      :right="$store.state.appConfig.isRTL"
                    >

                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>

                      <b-dropdown-item
                        v-if="hasPermissionToUpdate"
                        @click="edit(field.item)"
                      >
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Editar</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        :disabled="!hasPermissionToUpdate"
                        @click="showConfirmOrCancelDelete(field.item)"
                      >
                        <svg-icon
                          type="mdi"
                          size="16"
                          :path="mdiTrashCanOutline"
                        />
                        <span class="align-middle ml-50">Remover</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>

              <div class="mx-2 mb-2">
                <b-row>
                  <b-col
                    cols="12"
                    class="d-flex align-items-center justify-content-center"
                  >
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalItems"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>

                  </b-col>
                </b-row>
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-center mt-2">
        <b-col
          class="col d-flex justify-content-end"
          cols="12"
          md="12"
          lg="10"
        >
          <b-button
            id="companyBackBtn"
            variant="outline-primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :to=" { name: 'company-detail', params: { id: companyId } } "
          >
            Voltar
          </b-button>
        </b-col>
      </b-row>
    </div>
    <validation-observer
      v-slot="{ handleSubmit, invalid }"
      ref="refFormObserver"
    >
      <b-modal
        id="idModalCertificate"
        ref="modalCertificate"
        no-close-on-backdrop
        no-close-on-esc
        size="lg"
        centered
        :ok-disabled="invalid"
        hide-footer
        @ok="handleSubmit(save)"
        @cancel="cancel()"
        @hidden="cancel()"
      >
        <template #modal-title>
          Cadastro de Atestado
        </template>
        <b-form
          class="mt-1"
        >
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <client-select
                id="client"
                v-model="value.client_set"
                :readonly="!hasPermissionToUpdate"
                :required-field="true"
                @ready="syncLoad.ready('client')"
              />
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <certificate-status-select
                v-model="value.status_set"
                :required-field="true"
                :readonly="!hasPermissionToUpdate"
                @ready="syncLoad.ready('status')"
              />
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="end_authentication"
              >
                <b-form-group
                  label="Final da Autenticação"
                  label-for="end_authentication"
                >
                  <b-form-input
                    id="end_authentication"
                    v-model="value.end_authentication"
                    name="certificate"
                    placeholder="Final da Autenticação"
                    :readonly="!hasPermissionToUpdate"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="annotation"
              >
                <b-form-group
                  label="Anotações"
                  label-for="annotation"
                >
                  <b-form-textarea
                    id="annotation"
                    v-model="value.annotation"
                    trim
                    rows="7"
                    :placeholder="'Anotações'"
                    :readonly="!hasPermissionToUpdate"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row
            v-for="(file, index) in filesCertificate"
            :key="index"
          >
            <b-col
              cols="12"
              class="px-0"
            >
              <div
                class="d-flex justify-content-between"
                :style="index % 2 == 0 ? {'background-color': '#F6F6F9'} : {}"
              >
                <div
                  class="d-flex align-items-center ml-50"
                >
                  <b-button
                    class="btn-icon rounded-circle"
                    variant="flat-primary"
                    size="md"
                    @click="downloadFile(file)"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      size="16"
                    />
                  </b-button>
                  <span class="d-flex align-items-center">{{ file.file_name }}</span>
                </div>
                <b-button
                  class="btn-icon rounded-circle mr-50"
                  variant="flat-info"
                  size="md"
                  @click="showConfirmOrCancelDeleteFile(value, file)"
                >
                  <svg-icon
                    type="mdi"
                    size="18"
                    :path="mdiTrashCanOutline"
                  />
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-3 mb-1">
            <b-col class="footer-certificate-modal">
              <b-button
                v-b-tooltip.hover
                variant="outline-primary"
                size="sm"
                :disabled="!hasPermissionToUpdate"
                @click="selectFile()"
              >
                <input
                  id="add_certificate_file"
                  ref="fileInput"
                  type="file"
                  class="d-none"
                  @change="handleFile"
                >
                <svg-icon
                  type="mdi"
                  :path="mdiFileUploadOutline"
                />
                <span
                  class="font-weight-bolder"
                >
                  Adicionar Arquivo
                </span>
              </b-button>
              <div id="buttonsFooter">
                <b-button
                  variant="primary"
                  :disabled="invalid || !hasPermissionToUpdate"
                  @click="save()"
                >
                  Salvar
                </b-button>
                <b-button
                  class="ml-1"
                  variant="outline-primary"
                  @click="cancel()"
                >
                  Cancelar
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </validation-observer>
    <div>
      <b-button
        id="addItemBtn"
        v-b-tooltip.hover
        v-b-modal.idModalCertificate
        class="btn-icon rounded-circle m-3"
        variant="primary"
        size="md"
        :disabled="!hasPermissionToUpdate"
        title="Adicionar Atestado"
      >
        <svg-icon
          type="mdi"
          :path="mdiPlus"
        />
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BBadge,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BTable,
  BPagination,
  BMedia,
  BLink,
  BModal,
  VBModal,
  VBTooltip,
} from 'bootstrap-vue'

import {
  mdiPlus,
  mdiCogOutline,
  mdiFileDownloadOutline,
  mdiFileUploadOutline,
  mdiFileCertificateOutline,
  mdiTrashCanOutline,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'

import {
  required,
  confirmed,
} from '@validations'

import vSelect from 'vue-select'
import SvgIcon from '@jamescoyle/vue-icon'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import syslic from '@/syslic'
import router from '@/router'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import SyncLoad from '@/components/utils/syncLoad'
import ClientSelect from '@/components/client/ClientSelect.vue'
import CertificateStatusSelect from './components/CertificateStatusSelect.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BBadge,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BTable,
    BPagination,
    BMedia,
    BLink,
    BModal,
    SpinLoader,
    ClientSelect,
    CertificateStatusSelect,
    SvgIcon,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    VBModal,
    'b-tooltip': VBTooltip,
  },
  data() {
    const perPage = 10
    const totalItems = 0
    const currentPage = 1
    const searchQuery = ''
    const sortBy = 'name'
    const isSortDirDesc = false
    const perPageOptions = [10, 25, 50, 100]

    const tableColumns = [
      {
        key: 'field_id',
        label: 'ID',
        thStyle: { width: '10%' },
      },
      {
        key: 'field_client',
        label: 'Orgão',
      },
      {
        key: 'field_status',
        label: 'Status',
      },
      {
        key: 'field_end_authentication',
        label: 'Final da Autenticação',
      },
      {
        key: 'field_actions',
        label: '',
        thStyle: { width: '5%' },
      },
    ]

    const data = []

    const companyId = router.currentRoute.params.id

    const filesCertificate = []

    const value = {
      annotation: '',
      client: '',
      client_set: '',
      company: companyId,
      company_set: '',
      end_authentication: '',
      files_set: '',
      id: '',
      status: '',
      status_set: '',
    }

    const isLoaded = false

    const isAdd = true

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('certificate')

    syncLoad.onReady(() => {
      this.isLoaded = true
    })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.value)

    return {
      required,
      confirmed,
      refFormObserver,
      getValidationState,
      perPage,
      totalItems,
      currentPage,
      searchQuery,
      sortBy,
      isSortDirDesc,
      perPageOptions,
      tableColumns,
      data,
      value,
      filesCertificate,
      companyId,
      isLoaded,
      isAdd,
      syncLoad,
    }
  },
  computed: {
    hasPermissionToRead() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.company.can_read
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.company.can_write
    },
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.company.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.company.can_delete
    },
  },
  watch: {
    perPage: 'fetchData',
    currentPage: 'fetchData',
    searchQuery: function updateSearchQuery(value) {
      this.currentPage = 1
      this.searchQuery = value
      this.fetchData()
    },
    'value.client_set': function updateClient(obj) {
      if (obj) {
        this.value.client = obj.id
      }
    },
    'value.status_set': function updateStatus(obj) {
      if (obj) {
        this.value.status = obj.id
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      syslic
        .company
        .certificate
        .fetchAllCertificate({
          p: this.currentPage,
          page_size: this.perPage,
          search: this.searchQuery,
          company: this.companyId,
        })
        .then(response => {
          this.totalItems = response.count
          this.data = response.results
          this.syncLoad.ready('certificate')
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados.',
              text: 'Não foi possível ler os dados dos atestados de capacidade técnica, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.syncLoad.ready('certificate')
        })
    },
    deleteCertificate(item) {
      syslic
        .company
        .certificate
        .deleteCertificate(item.id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Atestado removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.data.splice(this.data.indexOf(item), 1)
        })
        .catch(() => {
          const textError = 'Não foi possível remover este atestado de capacidade técnica, por favor entre em contato com o administrador do sistema.'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover atestado de capacidade técnica.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você deseja remover este atestado de capacidade técnica.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteCertificate(item)
          }
        })
    },
    deleteCertificateFile(cert, item) {
      syslic
        .company
        .certificateFile
        .deleteCertificateFile(item.id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Documento removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })

          cert.files_set.splice(cert.files_set.indexOf(item), 1)
          this.filesCertificate.splice(this.filesCertificate.indexOf(item), 1)
        })
        .catch(() => {
          const textError = 'Não foi possível remover este documento, por favor entre em contato com o administrador do sistema.'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover documento.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDeleteFile(cert, item) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você deseja remover este arquivo do atestado de capacidade técnica.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteCertificateFile(cert, item)
          }
        })
    },
    add(data) {
      syslic
        .company
        .certificate
        .addCertificate(data)
        .then(response => {
          const promiseList = []

          this.filesCertificate.forEach(element => {
            const file = element
            file.certificate = response.data.id
            const promise = syslic.company.certificateFile.addCertificateFile(file)
            promiseList.push(promise)
          })

          return Promise.allSettled(promiseList)
        })
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Atestado adicionado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.fetchData()
          this.$refs.modalCertificate.hide()
        })
        .catch(error => {
          let textError = 'Não foi possível adicionar este atestado de capacidade técnica, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.name[0].includes('certificate with this name already exists.')) {
              textError = 'Já existe um atestado de capacidade técnica com este nome, não é possivel adicionar um atestado de capacidade técnica com nome repetido.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar atestado de capacidade técnica.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.$refs.modalCertificate.hide()
        })
    },
    update(id, data) {
      syslic
        .company
        .certificate
        .updateCertificate(id, data)
        .then(response => {
          const promiseList = []
          this.filesCertificate.forEach(element => {
            if (!('id' in element)) {
              const file = element
              file.certificate = response.data.id
              const promise = syslic.company.certificateFile.addCertificateFile(file)
              promiseList.push(promise)
            }
          })

          return Promise.allSettled(promiseList)
        })
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Atestado atualizado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.fetchData()
          this.$refs.modalCertificate.hide()
        })
        .catch(() => {
          const textError = 'Não foi possível atualizar este atestado de capacidade técnica, por favor entre em contato com o administrador do sistema.'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar atestado de capacidade técnica.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.$refs.modalCertificate.hide()
        })
    },
    edit(item) {
      this.value = JSON.parse(JSON.stringify(item))
      this.isAdd = false
      this.filesCertificate = item.files_set
      this.$refs.modalCertificate.show()
    },
    save() {
      if (this.isAdd) {
        this.add(this.value)
      } else {
        this.update(this.value.id, this.value)
      }
      this.isAdd = true
    },
    cancel() {
      this.$refs.modalCertificate.hide()
      this.value = {
        annotation: '',
        client: '',
        client_set: '',
        company: this.companyId,
        company_set: '',
        end_authentication: '',
        files_set: '',
        id: '',
        status: '',
        status_set: '',
      }
      this.filesCertificate = []

      this.isAdd = true
    },
    downloadFile(file) {
      syslic
        .company
        .certificateFile
        .fetchCertificateFile(file.id)
        .then(response => {
          const linkSource = `data:application/file;base64,${response.data.file}`
          const downloadLink = document.createElement('a')
          const fileName = file.file_name

          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha para realizar o download do documento.',
              text: 'Não foi possível realizar o download, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    handleFile(event) {
      const selectedFile = event.target.files[0]
      if (selectedFile.size > 15000000) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Falha ao adicionar atestado de capacidade técnica.',
            text:
              'O arquivo deve ter tamanho de até 15MB',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
            timeout: false,
          },
        })
        return
      }

      const reader = new FileReader()
      reader.onload = () => {
        const fileCreate = {
          certificate: this.value.id,
          file_name: selectedFile.name,
          file: reader.result,
        }
        this.filesCertificate.push(fileCreate)
      }
      reader.readAsDataURL(selectedFile)
    },
    selectFile() {
      this.$refs.fileInput.click()
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiPlus,
      mdiCogOutline,
      mdiFileDownloadOutline,
      mdiFileUploadOutline,
      mdiFileCertificateOutline,
      mdiTrashCanOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
.smallField {
  width: 5px !important;
}

.per-page-selector {
  width: 90px;
}

.dropdownfix .dropdown.show{
  position: fixed;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/base/core/colors/palette-variables.scss";

.card-title {
  color: $primary
}

#idModalCertificate .vc-slider {
  width: 100% !important;
}

#idModalCertificate .modal-footer {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: end;
}

#buttonsFooter .btn{
  min-width: 107px;
}

#addItemBtn {
  position: fixed;
  right: 0;
  bottom: 0;
}

.footer-certificate-modal {
  display: flex;
  width: 100%;

  justify-content: space-between;
}
</style>
