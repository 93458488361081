var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{attrs:{"name":"client","rules":{
    required: _vm.requiredField,
  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.label,"label-for":"client"}},[_c('vue-autosuggest',{class:errors.length > 0 ? 'error-select' : '',attrs:{"no-filter":"","suggestions":_vm.clientsOptions,"input-props":{
        id: 'client',
        ref: 'client',
        class: errors.length > 0 ? 'form-control is-invalid' : 'form-control',
        placeholder: _vm.label,
        readonly: _vm.readonly,
      },"limit":_vm.limitElements,"get-suggestion-value":_vm.getClientSelected},on:{"input":_vm.onInputClientChange,"selected":_vm.onSelectedClient,"blur":_vm.onBlur},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var suggestion = ref.suggestion;
return [_c('p',[_vm._v(_vm._s(suggestion.item.name))])]}}],null,true),model:{value:(_vm.clientSearch),callback:function ($$v) {_vm.clientSearch=$$v},expression:"clientSearch"}},[_c('template',{slot:"after-suggestions"},[(!_vm.hasClients)?_c('span',{staticClass:"isEmpty d-flex justify-content-center"},[_vm._v(" Não há opções de "+_vm._s(_vm.label)+". ")]):_vm._e()])],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }