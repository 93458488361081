<template>
  <div>
    <validation-provider
      #default="{ errors }"
      name="companyCertificateStatus"
      :rules="requiredField ? 'required' : ''"
    >
      <b-form-group
        label="Status"
        label-for="companyCertificateStatus"
      >
        <v-select
          id="companyCertificateStatus"
          v-model="data"
          :class="errors.length > 0 ? 'error-select' : ''"
          :filter="fuseSearch"
          label="name"
          :options="dataOptions"
          :placeholder="'Status'"
          :disabled="readonly"
          :clearable="!readonly"
        >
          <template #option="{ name, color }">
            <b-badge
              pill
              class="text-capitalize"
              :style="{'background-color': color}"
            >
              {{ name | truncate(18, '...') }}
            </b-badge>
          </template>

          <template #selected-option="{ name, color }">
            <b-badge
              pill
              class="text-capitalize"
              :style="{'background-color': color}"
            >
              {{ name | truncate(18, '...') }}
            </b-badge>
          </template>

          <template #no-options>
            Não há opções de status.
          </template>
        </v-select>
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
  </div>
</template>
<script>
import {
  BFormGroup,
  BBadge,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import {
  required,
} from '@validations'

import {
  useToast,
} from 'vue-toastification/composition'

import vSelect from 'vue-select'
import Fuse from 'fuse.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import syslic from '@/syslic'
import truncate from '@/components/utils/filters/truncate'

export default {
  components: {
    BFormGroup,
    BBadge,
    ValidationProvider,
    vSelect,
  },
  filters: {
    truncate,
  },
  model: {
    prop: 'statusValue',
    event: 'statusValueChange',
  },
  props: {
    statusValue: {
      type: [Object, String],
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const dataOptions = []
    const data = ''

    return {
      dataOptions,
      required,
      data,
    }
  },
  watch: {
    statusValue: function updateStatusValue(val) {
      const newVal = JSON.parse(JSON.stringify(val))
      const oldVal = JSON.parse(JSON.stringify(this.data))

      if (newVal !== oldVal) {
        this.data = this.statusValue
      }
    },
    data: function updateData(val) {
      this.$emit('statusValueChange', val)
    },
  },
  created() {
    this.data = this.statusValue
    this.onCreate()
  },
  methods: {
    onCreate() {
      syslic
        .company
        .certificateStatus
        .fetchAllCertificateStatus()
        .then(response => {
          this.dataOptions = response.results
          this.$emit('ready', true)
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados.',
              text: 'Não foi possível ler os dados do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.$emit('ready', true)
        })
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name'],
        shouldSort: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss" scoped>
.v-select {
  background-color: #fff;
}
</style>
