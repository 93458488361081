<template>
  <validation-provider
    #default="{ errors }"
    name="client"
    :rules="{
      required: requiredField,
    }"
  >
    <b-form-group
      :label="label"
      label-for="client"
    >
      <vue-autosuggest
        v-model="clientSearch"
        no-filter
        :suggestions="clientsOptions"
        :input-props="{
          id: 'client',
          ref: 'client',
          class: errors.length > 0 ? 'form-control is-invalid' : 'form-control',
          placeholder: label,
          readonly: readonly,
        }"
        :limit="limitElements"
        :class="errors.length > 0 ? 'error-select' : ''"
        :get-suggestion-value="getClientSelected"
        @input="onInputClientChange"
        @selected="onSelectedClient"
        @blur="onBlur"
      >
        <template slot-scope="{suggestion}">
          <p>{{ suggestion.item.name }}</p>
        </template>
        <template slot="after-suggestions">
          <span
            v-if="!hasClients"
            class="isEmpty d-flex justify-content-center"
          >
            Não há opções de {{ label }}.
          </span>
        </template>
      </vue-autosuggest>
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>
<script>

import {
  VueAutosuggest,
} from 'vue-autosuggest'

import {
  BFormGroup,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import {
  required,
} from '@validations'

import {
  useToast,
} from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import syslic from '@/syslic'

export default {
  components: {
    BFormGroup,
    VueAutosuggest,
    ValidationProvider,
  },
  model: {
    prop: 'clientValue',
    event: 'clientChange',
  },
  props: {
    clientValue: {
      type: [Object, String],
      required: false,
      default: '',
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: 'Órgão Gerenciador',
    },
  },
  data() {
    const clientTemplate = {
      name: '',
      name_fantasy: null,
      ie: null,
      cnpj: '',
      email: null,
      phone_number: null,
      city: null,
      city_set: null,
      state: null,
      state_set: null,
      region_freight: null,
      region_freight_set: null,
    }
    const clientsOptions = []
    const hasClients = false
    return {
      limitElements: 20,
      clientTemplate,
      clientsOptions,
      hasClients,
      required,
      clientSearch: '',
    }
  },
  computed: {
    client: {
      get() {
        return this.clientValue
      },
      set(value) {
        this.$emit('clientChange', value)
      },
    },
  },
  watch: {
    clientValue: function updateClient(client) {
      if (client) {
        this.clientSearch = this.getClientSelected({ item: client })
      } else {
        this.clientSearch = ''
      }
    },
  },
  created() {
    if (this.clientValue) {
      this.clientSearch = this.getClientSelected({ item: this.clientValue })
    }
    this.hasClient()
  },
  methods: {
    hasClient() {
      syslic
        .client
        .fetchClientList({
          search: '',
          p: 1,
          page_size: 5,
        })
        .then(response => {
          this.hasClients = response.data.results.length > 0

          this.$emit('ready', true)
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados.',
              text: 'Não foi possível ler os dados do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })

          this.$emit('ready', true)
        })
    },
    onSelectedClient(selected) {
      if (selected === '' || selected === undefined) {
        return
      }
      this.client = selected.item
      this.$emit('change', selected.item)
    },
    getClientSelected(suggestion) {
      return `${suggestion.item.name}`
    },
    onInputClientChange(text) {
      syslic
        .client
        .fetchClientList({
          search: text,
          p: 1,
          page_size: this.limitElements,
        })
        .then(response => {
          if (response.data.results.length > 0) {
            this.hasClients = true
            this.clientsOptions = [{
              data: response.data.results,
            }]
          } else {
            this.hasClients = false
            this.clientsOptions = [
              { data: [this.clientTemplate] },
            ]
          }
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados.',
              text: 'Não foi possível ler os dados do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    onBlur() {
      if (this.client) {
        const clientSelected = this.getClientSelected({ item: this.client })
        if (this.clientSearch !== clientSelected) {
          this.client = ''
        }
      } else {
        this.clientSearch = ''
      }
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';

.isEmpty {
  position: absolute;
  width: 100%;
  padding-top: 1.5%;
  top: 0;
}
</style>
